import * as React from 'react';
import styled from 'styled-components';
// import {useStaticQuery, graphql} from 'gatsby';
// import {GatsbyImage, getImage} from 'gatsby-plugin-image';
// import Gallery from '@browniebroke/gatsby-image-gallery';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';
import Page from '../components/Page';
import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

// styles
const TitleWrapper = styled(Box).attrs({
  mt: '35px',
})``;

const SubTitle = styled(Box).attrs({
  mt: '40px',
  mb: '25px',
  fontSize: ['20px', null, null, '22px'],
  textAlign: 'left',
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
  textAlign: 'left',
})`
  font-family: 'Open Sans';
`;

const Anchor = styled.a`
  color: #6d7770;
  margin-top: 5px;
  word-wrap: break-word;
  :hover {
    color: black;
  }
`;

// const useGallery = () => {
//   const data = useStaticQuery(graphql`
//     query GalleryImages {
//       images: allS3Object(filter: {Key: {glob: "content/gallery/*"}}) {
//         nodes {
//           Key
//           localFile {
//             childImageSharp {
//               thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
//               full: gatsbyImageData(layout: FULL_WIDTH)
//             }
//           }
//         }
//       }
//     }
//   `);
//   return data.images.nodes.map(node => node.localFile.childImageSharp);
// };

const WeatherPage = () => {
  return (
    <Page>
      <ContentWrapper>
        <TitleWrapper>
          <Title text={'Weather'} />
        </TitleWrapper>
        <Box m={'0 auto'} maxWidth={'1000px'}>
          <Content>
            Beaver Creek is BEAUTIFUL, but be aware that the altitude is very high and mountain weather can be extreme!
          </Content>
          <Content>
            Before your trip, we recommend checking the weather forecast for Avon, Colorado (zip code 81620) at{' '}
            <Anchor
              href={
                'https://weather.com/weather/tenday/l/Avon+CO?canonicalCityId=7d048ff40cdf75e1cf2621d583484e90f8515883d63902cf987ff888ae1ca3d0'
              }
            >
              www.weather.com
            </Anchor>
            . Colorado has more than 300 days of sunshine each year, but the weather is often unpredictable and can
            quickly change in the mountains.
          </Content>
          <Content>The temperature during the day may fluctuate between 5 and 60 degrees!</Content>
          <Content>
            In the last few years, large storms have become more prevalent. Please allow time to get from Denver to
            Vail. Mountain passes can close or be slow. If you are driving, try to avoid driving at night from Denver to
            Beaver Creek.
          </Content>
          <Content>
            At over 8,000 feet, the top of Beaver Creek mountain, exposure to the sun is 40% greater than it is at sea
            level. Sunscreen (at least SPF 30) is recommended for all exposed skin, particularly the face, lips and
            neck. Be certain to protect your eyes with sunglasses. You will find Beaver Creek to be an arid climate, so
            hand lotion and lip balm are essential.
          </Content>
          <SubTitle>High Altitude</SubTitle>
          <Content>
            Beaver Creek is more than 8,000 feet above sea level and guests may struggle with altitude sickness.
            Altitude sickness can hit anyone regardless of physical condition or age. The most common symptoms include:
            <br />
            headache
            <br />
            dizziness and nausea
            <br />
            fatigue
            <br />
            shortness of breath
            <br />
            loss of appetite
            <br />
            disturbed sleep
          </Content>
          <Content>
            <i>Staying hydrated is essential!</i>
          </Content>
          <Content>
            If you can, consider arriving early to give yourself time to adjust. We usually feel back to normal after 24
            hours have passed.
          </Content>
          <Content>
            Also, please note that alcohol is more potent at high altitudes. Don’t find out the hard way! When drinking,
            we recommend you alternate between a drink and a glass of water. <i>ONE DRINK HERE = 2 DRINKS THERE!</i>
          </Content>
        </Box>
      </ContentWrapper>
    </Page>
  );
};

export default WeatherPage;
