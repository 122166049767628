import * as React from 'react';
import styled from 'styled-components';
import {withPrefix} from 'gatsby';
import {Helmet} from 'react-helmet';
import {ThemeProvider} from 'styled-components';

import {defaultTheme} from '../styleguide/DefaultTheme';

import 'fontsource-open-sans/300.css';
import 'normalize.css';

import NavBar from '../components/NavBar';
import {currentYear} from '../utils';

// styles
const Wrapper = styled.div`
  display: grid;
  grid-template-areas: 'header' 'content' 'footer';
  grid-template-rows: auto auto 20px;
`;

const Header = styled.div`
  grid-area: header;
  align-items: flex-end;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 20;
`;

const Content = styled.div`
  grid-area: content;
  text-align: center;
  align-self: end;
  margin-bottom: 50px;
`;

const Copywrite = styled.p`
  grid-area: footer;
  font-family: 'Open Sans';
  font-size: 10px;
  text-align: right;
  margin: 10px 0 0;
  padding: 20px;
  color: #d0d0d0;
  background-color: #6d7770;
`;

export default function Layout({children}) {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Erica & Alex</title>
          <link rel="canonical" href="https://thelakners.com" />
        </Helmet>
        <Header>
          <NavBar hideNavBackground={false} />
        </Header>
        <Content>{children}</Content>
        <Copywrite>© Copyright {currentYear} -- Designed & Built by Erica Kantor</Copywrite>
      </Wrapper>
    </ThemeProvider>
  );
}
