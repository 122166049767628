import * as React from 'react';
import styled from 'styled-components';

import Box from '../components/Box';
import 'normalize.css';

// styles
const Wrapper = styled(Box).attrs({
  m: ['25px', null, null, '70px 100px'],
  display: 'flex',
  justifyContent: 'center',
})`
  justify-content: center;
`;

const Content = styled(Box).attrs({
  maxWidth: '1000px',
})``;

const ContentWrapper = ({children}) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default ContentWrapper;
